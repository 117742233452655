p {
  color: var(--primaryTextColor);
}
h1 {
  color: var(--primaryTextColor);
}

.MuiInputLabel-outlined {
  transform: translate(12px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}
.MuiSelect-outlined.MuiSelect-outlined {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}
.MuiFormHelperText-root.Mui-error {
  color: var(--darkprimaryColor) !important;
}

#recaptcha,
#rc-imageselect {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 25px;
  background: #fff;
  font-family: var(--regular);
}
.inputRounded::focus-visible {
  outline: "none";
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--secondaryColor) !important;
}

.tCampos {
  color: var(--primaryTextColor);
  font-size: 16px;
  margin: 5px 0 2px 15px;
  font-family: var(--bold);
}
.tFormulario {
  text-align: center;
  padding: 10px;
  font-size: 38px;
}

.requerido {
  color: var(--primaryColor);
}

.politica {
  color: var(--primaryTextColor) !important;
  padding: 0 5px 0 0 !important;
  text-decoration: underline;
  cursor: pointer;
}

fieldset {
  display: none;
}

.textarea {
  width: 100% !important;
  height: 100px !important;
  border: none !important;
  border-radius: 25px !important;
  padding: 10px !important;
  font-family: var(--light);
}

/*Modals del login*/

.modalstilos {
  background: #ffffff;
  font-family: var(--regular) !important;
  width: 100%;
}

.modalstilos p {
  font-family: var(--medium) !important;
  color: var(--darkprimaryColor) !important;
}

.modalstilos h2 {
  font-family: var(--bold) !important;
  color: var(--secondaryColor) !important;
}

.titulos h2 {
  text-align: center;
  font-size: 26px;
  color: var(--secondaryColor);
}

.text-modal-login {
  font-size: 14px;
}

.headerregister {
  width: 100%;
}

.footerLuz {
  position: absolute !important;
  bottom: 0% !important;
}

@media only screen and (max-width: 600px) {
  .modalPoints {
    width: 90%;
    left: 5%;
  }

  .tCampos {
    font-size: 14px;
  }
  .tFormulario {
    font-size: 24px;
  }
}

@media only screen and (min-width: 600px) {
  .text-modal-login {
    font-size: 16px;
  }
  .titulos h2 {
    text-align: center;
    font-size: 26px;
    color: var(--secondaryColor);
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .textarea {
    height: 150px !important;
  }
}

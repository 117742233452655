.menuLink {
  background: #000000 !important;
}

.logoIni {
  width: 35%;
  padding: 0 30px;
}
.itemM {
  color: var(--lightprimaryColor) !important;
  padding-right: 10% !important;
}

.titlesh2 {
  font-size: 1.5em !important;
  font-family: var(--bold);
  text-align: center;
}

.hacemos {
  padding-top: 10%;
  width: 80% !important;
  margin: 15px auto !important;
}

.iconItem {
  transition: all 300ms ease 0ms;
}

.iconItem:hover {
  filter: grayscale(100%) !important;
}

.seccionVideo {
  background: #000000;
}

.seccionVideo img {
  width: 80% !important;
  cursor: pointer;
}

.seccionEventos {
  margin-top: -15%;
  padding-bottom: 10%;
}

/*Video Modal*/
.MuiPaper-root {
  background: transparent !important;
  box-shadow: none !important;
}

.imgTitle {
  width: 60% !important;
}

.textosP {
  font-size: 1em;
  font-family: var(--light);
  color: #000000;
  padding: 0 10% 0 0;
  text-align: justify;
}

.titulosh {
  font-size: 1em;
  padding: 0 10% 0 0;
  text-align: center;
}

.listasD {
  font-family: var(--light);
  font-size: 1.2em;
}

.textosAlquiler {
  font-size: 1em;
  font-family: var(--light);
  color: #000000;
  padding: 0 5% 0 5%;
  text-align: right;
}

.textosAlquilerlist {
  font-family: var(--light);
  font-size: 1.2em;
  text-align: right;
  padding: 0 15% 0 5%;
}

.imgAlquiler {
  float: right !important;
  margin: 0 5% 0 0;
}

.imagenMedia {
  width: 100%;
}

.galeriaT {
  background: #000000;
  color: var(--lightprimaryColor);
  padding: 6%;
  font-family: var(--regular);
  text-align: center;
}

.galeriaT:hover {
  color: var(--primaryColor);
}

.galeria {
  max-width: 98% !important;
  margin: 0 auto !important;
}

.galeriaBk {
  margin: 0 auto !important;
  width: 98% !important;
}

.galeriageneral {
  background: #000000;
}


.slick-next {
  transform: translate(-100%, -50%) !important;
}

.slick-prev {
  transform: translate(100%, -50%) !important;
  z-index: 1000;
}

.galeri2 {
  background: #000000;
  padding: 4%;
}

.contactenosT {
  text-align: center;
  padding: 5% 0 2% 0;
  font-family: var(--regular);
}

.formContacto {
  background: #d6d6d6;
  background-image: url("../../assets//images/mapa.png");
  background-repeat: repeat;
  background-position: 0% 0%;
  background-size: 100%;
  background-attachment: fixed !important;
  width: 90% !important;
  margin: 0 auto 5% auto !important;
  border-radius: 25px;
  padding: 10px;
}

.footer {
  background: #000000;
  padding: 10% 0;
}

.footerImg {
  max-width: 300px;
  min-width: 120px;
}

.redes {
  max-width: 200px;
  min-width: 80px;
}

.footer p {
  color: var(--lightprimaryColor);
  font-size: 1.3em;
  font-family: var(--light);
}

.copy {
  font-size: 1em !important;
  text-align: center;
}

.alquilerMovil {
  display: none;
}

@media (min-width: 600px) and (max-width: 900px) {
  .formContacto {
    background-size: 200%;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .menuMovil {
    width: 80% !important;
  }

  .logoIni {
    width: 50%;
  }

  .hacemos {
    width: 98% !important;
  }

  .imgTitle {
    width: 80% !important;
  }

  .textosP,
  .titulosh {
    padding: 0 30px;
  }

  .imgAlquiler {
    margin: 0 3% 0 0;
  }

  .textosAlquilerlist {
    padding: 0 5% 0 5%;
  }

  .galeriaT {
    padding: 10%;
  }

  .galeria {
    max-width: 90% !important;
  }

  .galeri2 {
    padding: 8%;
  }

  .contactenosT {
    text-align: center;
    padding: 10% 0 5% 0;
  }

  .alquilerMovil {
    display: flex;
  }

  .alquilerEscritorio {
    display: none !important;
  }

  .textarea {
    height: 200px !important;
  }

  .formContacto {
    background-size: 400%;
  }

  .imgCenter {
    margin: 0 auto !important;
  }
  
  .listasD {
    padding: 0 0 0 45px !important;
  }
}

@font-face {
  font-family: "RobotoR";
  src: local("Roboto Regular"),
    url(./assets/ttf/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoM";
  src: local("Roboto Medium"),
    url(./assets/ttf/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoB";
  src: local("Roboto Bold"),
    url(./assets/ttf/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoL";
  src: local("Roboto Light"),
    url(./assets/ttf/Roboto-Light.ttf) format("truetype");
}

body {
  background-repeat: repeat;
  background-position: 0% 0%;
  background-size: 100%;
  background-attachment: fixed !important;
  background-image: var(--bkEscritorio);
  /* background: linear-gradient(
    0deg,
    rgba(11, 78, 132, 1) 66%,
    rgba(0, 5, 22, 1) 100%
  ) !important; */
}

::-webkit-scrollbar {
  width: 4px;
  background: var(--lightprimaryColor);
  border: none;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primaryTextColor);
  border-radius: 10px;
}

.sinP {
  padding: 0 !important;
}

.sinM {
  margin: 0 !important;
}

.index1{
  position: relative !important;
  z-index: 1000 !important;
}

.img-thumbnail {
  background: transparent !important;
  border: none !important;
  z-index: 1000 !important;
  border-radius: 0 !important;
}

.Mui-disabled {
  background-color: rgba(0, 0, 0, 0) !important;
}

@media (min-width: 300px) and (max-width: 600px) {
  body {
    background-image: var(--bkMovil) !important;
  }
}

@media (min-height: 1800px) {
  body {
    background-image: var(--bkTotem) !important;
  }
}
